import React, { useEffect, useState } from "react";
import { HexColorPicker } from "react-colorful";

const GalleryModal = ({ isOpen, onClose }) => {
  const API_URL = import.meta.env.VITE_API_URL; // Base URL from .env

  const [galleryData, setGalleryData] = useState({
    title: "",
    subtitle: "",
    bgColor: "",
    textColor: "",
    imageBgColor: "",
    imageTextColor: "",
    items: [],
  });

  useEffect(() => {
    fetch(`${API_URL}/gallery`)
      .then((res) => res.json())
      .then((data) => {
        setGalleryData(data);
      });
  }, [API_URL]);

  // Handler for updating simple string fields (title, subtitle)
  const handleFieldChange = (field, value) => {
    setGalleryData((prevData) => ({ ...prevData, [field]: value }));
  };

  // Handler for updating color fields
  const handleColorChange = (field, value) => {
    setGalleryData((prevData) => ({ ...prevData, [field]: value }));
  };

  // Handler for updating individual image items
  const handleInputChange = (index, field, value) => {
    const updatedItems = [...galleryData.items];
    updatedItems[index][field] = value;
    setGalleryData({ ...galleryData, items: updatedItems });
  };

  // Add a new image item
  const addNewImage = () => {
    setGalleryData({
      ...galleryData,
      items: [
        ...galleryData.items,
        { image: "", subtitle: "", title: "", description: "" },
      ],
    });
  };

  // Remove an image item
  const handleRemoveImage = (index) => {
    setGalleryData({
      ...galleryData,
      items: galleryData.items.filter((_, i) => i !== index),
    });
  };

  // Save handler with PATCH request
  const handleSave = async () => {
    const updatedItems = galleryData.items.map((item) => ({
      ...item,
      image: item.image, // Keep as URL
    }));

    const dataToSend = {
      ...galleryData,
      items: updatedItems,
    };

    try {
      const response = await fetch(`${API_URL}/gallery/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      });

      if (!response.ok) {
        throw new Error("Failed to update gallery data");
      }

      console.log("Gallery Data Saved:", dataToSend);
      onClose();
      window.location.reload();
    } catch (error) {
      console.error("Error saving gallery data:", error);
    }
  };

  return (
    <dialog
      id="gallery_modal"
      className={`modal ${isOpen ? "modal-open" : ""}`}
    >
      <div className="modal-box w-11/12 max-w-5xl relative">
        <button
          onClick={onClose}
          className="btn btn-sm btn-circle absolute right-2 top-2"
        >
          ✕
        </button>
        <h3 className="font-bold text-lg mb-4">Edit Gallery</h3>

        {/* Title Input */}
        <div className="mb-4">
          <label className="block mb-1">Title:</label>
          <input
            type="text"
            value={galleryData.title}
            onChange={(e) => handleFieldChange("title", e.target.value)}
            className="input input-bordered w-full mb-1"
          />
        </div>

        {/* Subtitle Input */}
        <div className="mb-4">
          <label className="block mb-1">Subtitle:</label>
          <textarea
            value={galleryData.subtitle}
            onChange={(e) => handleFieldChange("subtitle", e.target.value)}
            className="textarea textarea-bordered w-full mb-1"
            rows={3}
          />
        </div>

        {/* Image Items Table */}
        <table className="w-full table-fixed border-collapse">
          <thead>
            <tr>
              <th className="border p-2 w-1/5">Image URL</th>
              <th className="border p-2 w-1/5">Subtitle</th>
              <th className="border p-2 w-1/5">Title</th>
              <th className="border p-2 w-1/5">Description</th>
              <th className="border p-2 w-1/5">Actions</th>
            </tr>
          </thead>
          <tbody>
            {galleryData.items.map((item, index) => (
              <tr key={index}>
                <td className="border p-2 align-middle">
                  <input
                    type="text"
                    value={item.image}
                    onChange={(e) => handleInputChange(index, "image", e.target.value)}
                    className="input input-bordered w-full"
                  />
                </td>
                <td className="border p-2 align-middle">
                  <input
                    type="text"
                    value={item.subtitle}
                    onChange={(e) =>
                      handleInputChange(index, "subtitle", e.target.value)
                    }
                    className="input input-bordered w-full"
                  />
                </td>
                <td className="border p-2 align-middle">
                  <input
                    type="text"
                    value={item.title}
                    onChange={(e) =>
                      handleInputChange(index, "title", e.target.value)
                    }
                    className="input input-bordered w-full"
                  />
                </td>
                <td className="border p-2 align-middle">
                  <input
                    type="text"
                    value={item.description}
                    onChange={(e) =>
                      handleInputChange(index, "description", e.target.value)
                    }
                    className="input input-bordered w-full"
                  />
                </td>
                <td className="border p-2 align-middle">
                  <button
                    onClick={() => handleRemoveImage(index)}
                    className="btn btn-sm btn-error"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="flex justify-between">
          <button onClick={addNewImage} className="btn btn-primary mt-4">
            + Add Image
          </button>
          <button onClick={handleSave} className="btn btn-success mt-4 text-white">
            Save Changes
          </button>
        </div>
      </div>
    </dialog>
  );
};

export default GalleryModal;
