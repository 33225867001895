import React, { useState, useEffect } from 'react';
import { HexColorPicker } from 'react-colorful'; // Ensure you have react-colorful installed for color picking

const CardsModal = ({ isOpen, onClose }) => {
  const apiUrl = import.meta.env.VITE_API_URL;

  const [heroData, setHeroData] = useState({
    divider: "",
    bgColor: "black",
    textColor: "white",
    heading: "What you need is what you get",
    overlayColor: "rgba(0, 0, 0, 0.8)",
    overlayTextColor: "white",
    cards: [
      {
        imageSrc: 'https://example.com/image1.png',
        title: 'Card 1',
        additionalDetails: 'This is a detail about Card 1.',
      },
      {
        imageSrc: 'https://example.com/image2.png',
        title: 'Card 2',
        additionalDetails: 'This is a detail about Card 2.',
      },
      {
        imageSrc: 'https://example.com/image3.png',
        title: 'Card 3',
        additionalDetails: 'This is a detail about Card 3.',
      },
    ]
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiUrl}/home/cards/`);
        const data = await response.json();
        setHeroData(data);
      } catch (error) {
        console.error("Error fetching hero data:", error);
      }
    };

    fetchData();
  }, []);

  const handleCardChange = (index, field, value) => {
    const newCards = [...heroData.cards];
    newCards[index][field] = value;
    setHeroData((prevData) => ({
      ...prevData,
      cards: newCards,
    }));
  };

  const addCard = () => {
    setHeroData((prevData) => ({
      ...prevData,
      cards: [
        ...prevData.cards,
        {
          imageSrc: '',
          title: '',
          additionalDetails: '',
        },
      ],
    }));
  };

  const handleSubmit = async () => {
    try {
      const payload = JSON.stringify(heroData);

      const response = await fetch(`${apiUrl}/home/cards/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: payload,
      });

      if (response.ok) {
        alert('Updated Cards successfully!');
        window.location.reload();
        onClose();
      } else {
        console.error('Failed to update Hero Data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const overlayColorWithOpacity = (color) => {
    const rgba = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*[\d\.]+)?\)/);
    if (rgba) {
      return `rgba(${rgba[1]}, ${rgba[2]}, ${rgba[3]}, 0.8)`;
    }
    return color;
  };

  return (
    <dialog className={`modal ${isOpen ? 'modal-open' : ''}`}>
      <div className="modal-box w-11/12 max-w-2xl relative">
        <button onClick={onClose} className="btn btn-sm btn-circle absolute right-2 top-2">✕</button>

        <h3 className="font-bold text-lg mb-4">Edit Cards Section</h3>

        <div className="mb-4">
          <label className="block mb-1">Heading:</label>
          <input
            type="text"
            value={heroData.heading}
            onChange={(e) => setHeroData({ ...heroData, heading: e.target.value })}
            className="input input-bordered w-full mb-2"
          />
        </div>

        <table className="table w-full">
          <thead>
            <tr>
              <th>Image Link</th>
              <th>Title</th>
              <th>Details</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {heroData.cards.map((card, index) => (
              <tr key={index}>
                <td>
                  <input
                    type="text"
                    value={card.imageSrc}
                    onChange={(e) => handleCardChange(index, 'imageSrc', e.target.value)}
                    className="input input-bordered w-full"
                    placeholder="Enter image link"
                  />
                  {card.imageSrc && (
                    <img src={card.imageSrc} alt="Card" className="mt-2 w-24 h-24 object-cover" />
                  )}
                </td>
                <td>
                  <input
                    type="text"
                    value={card.title}
                    onChange={(e) => handleCardChange(index, 'title', e.target.value)}
                    className="input input-bordered w-full"
                    placeholder="Enter card title"
                  />
                </td>
                <td>
                  <textarea
                    value={card.additionalDetails}
                    onChange={(e) => handleCardChange(index, 'additionalDetails', e.target.value)}
                    className="textarea textarea-bordered w-full"
                    rows={2}
                    placeholder="Enter additional details"
                  />
                </td>
                <td>
                  <button
                    className="btn btn-error"
                    onClick={() => {
                      const updatedCards = heroData.cards.filter((_, i) => i !== index);
                      setHeroData((prevData) => ({ ...prevData, cards: updatedCards }));
                    }}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className="btn btn-primary mt-4" onClick={addCard}>Add Card</button>

        <div className="modal-action">
          <button className="btn" onClick={handleSubmit}>Save Changes</button>
        </div>
      </div>
    </dialog>
  );
};

export default CardsModal;
